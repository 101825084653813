import * as React from "react"
import { Link, navigate } from "gatsby"
import { useEffect, useRef, useState } from "react"
import moment from "moment"
import { Editor } from "@tinymce/tinymce-react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Layout from "../../Layout/layout"
import Seo from "../../seo"
import AxiosInstance from "../../../services/axiosInstance"
import "./event.css"
import { DATE_FORMAT } from "../eventHelper"
import Tags from "./tags"
import PeopleAndPermissionPanel from "./PeopleAndPermissionPanel"
import Translate from "../../translationHelper"

if (typeof window !== "undefined") {
  require("tinymce/tinymce")
  require("tinymce/icons/default")
  require("tinymce/themes/silver")
  require("tinymce/plugins/link")
  require("tinymce/plugins/image")
  require("tinymce/plugins/advlist")
  require("tinymce/plugins/autolink")
  require("tinymce/plugins/lists")
  require("tinymce/plugins/preview")
  require("tinymce/plugins/searchreplace")
  require("tinymce/plugins/table")
  require("tinymce/plugins/wordcount")
  require("tinymce/models/dom/model")
}

const Detail = ({ selectedId }) => {
  const [title, setTitle] = useState(""),
    [eventTime, setEventTime] = useState(""),
    [eventEndTime, setEventEndTime] = useState(""),
    [eventDuration, setEventDuration] = useState(""),
    [type, setType] = useState(""),
    [content, setContent] = useState(""),
    [metaJson, setMetaJson] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [showDescription, setShowDescription] = useState(false),
    [isShowPeoplePanel, setIsShowPeoplePanel] = useState(false),
    [eventContactData, setEventContactData] = useState([]),
    [contactSelectedAddress, setContactSelectedAddress] = useState("")

  const editorRef = useRef(null)

  const getEvent = selectedId => {
    return new Promise(async () => {
      await AxiosInstance.get(`/calendar/detail/${selectedId}`).then(function (response) {
        if (response.status === 200) {
          const event = response.data.event
          setTitle(event.title)
          setType(event.eventType)
          setEventTime(moment(event.startTime).format(DATE_FORMAT))
          setEventEndTime(moment(event.endTime).format(DATE_FORMAT))
          setEventDuration(moment(event.endTime).diff(moment(event.startTime), "minutes"))
          setMetaJson(event.metaJson)
          setContent(event.metaJson.event_note)
          setTags(event.metaJson.event_tags ? event.metaJson.event_tags : [])
          setTempTags(event.metaJson.event_tags ? event.metaJson.event_tags : [])
          setShowDescription(event.metaJson.show_description ? event.metaJson.show_description : false)
          setEventContactData(event.calendarContacts ? event.calendarContacts : [])
        }
      })
    })
  }

  const submitEvent = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    metaJson["event_note"] = formData.get("content")
    metaJson["event_tags"] = tempTags
    metaJson["show_description"] = showDescription
    AxiosInstance.post(`/calendar/edit/${selectedId}`, {
      title: formData.get("title"),
      startTime: moment(eventTime).format(DATE_FORMAT),
      endTime:
        eventDuration === "Custom"
          ? moment(formData.get("endTime")).format(DATE_FORMAT)
          : moment(eventTime).add(eventDuration, "minutes").format(DATE_FORMAT),
      eventType: type,
      metaJson: metaJson,
    }).catch(function (error) {
      console.log(error)
    })
  }

  const deleteEvent = () => {
    if (!window.confirm("Delete this event?")) {
      return false
    }
    return new Promise(async () => {
      await AxiosInstance.delete(`/calendar/` + selectedId).then(response => {
        navigate("/calendar")
        return response
      })
    })
  }

  const openAddressOnGoogleMap = () => {
    const googleMapsUrl = `https://www.google.com/maps/dir/${contactSelectedAddress}`
    window.open(googleMapsUrl, "_blank")
  }

  useEffect(() => {
    getEvent(selectedId)
  }, [])

  return (
    <Layout>
      <Seo title={title} />
      <div id="right-section" className="h-100">
        <div className="row g-0" id="eventTemplate">
          <form onSubmit={submitEvent} method="POST">
            <div className="col" id="page-urls">
              <div className="row">
                <div className="col-lg-12 eventDetailLeftSection">
                  <div className="row g-0">
                    <div className="col-sm-7 mb-3">
                      <div className="pageTitle_Editor">
                        <Link to={"/calendar"} className="goBack">
                          <i className="bi bi-arrow-90deg-left" />
                        </Link>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          placeholder="{Translate('Title / Summary')}"
                          required
                          defaultValue={title}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-12 mb-3 contactDetailsContainer">
                      <div className="row g-0">
                        <div className="col position-relative">
                          <div className="event_tabs">
                            <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                              <li className="nav-item">
                                <Link to={"/calendar?addEvent=1"} className="btn btn-primary me-1 mt-1">
                                  <i className="bi bi-plus-lg" /> {Translate("Add New")}
                                </Link>
                              </li>
                              <li className="nav-item" role="presentation">
                                <OverlayTrigger overlay={<Tooltip>{Translate("Month")}</Tooltip>}>
                                  <Link to="/calendar?view=dayGridMonth" className={`nav-link`}>
                                    <i className="bi bi-calendar3" />
                                  </Link>
                                </OverlayTrigger>
                              </li>
                              <li className="nav-item" role="presentation">
                                <OverlayTrigger overlay={<Tooltip>{Translate("Week")}</Tooltip>}>
                                  <Link to="/calendar?view=timeGridWeek" className="nav-link">
                                    <i className="bi bi-layout-three-columns" />
                                  </Link>
                                </OverlayTrigger>
                              </li>
                              <li className="nav-item" role="presentation">
                                <OverlayTrigger overlay={<Tooltip>{Translate("Day")}</Tooltip>}>
                                  <Link to="/calendar?view=timeGridDay" className="nav-link">
                                    <i className="bi bi-app" />
                                  </Link>
                                </OverlayTrigger>
                              </li>
                              <li className="nav-item" role="presentation">
                                <OverlayTrigger overlay={<Tooltip>{Translate("Agenda")}</Tooltip>}>
                                  <Link to="/calendar/agenda" className={`nav-link`} id="agenda-tab">
                                    <i className="bi bi-arrow-clockwise" />
                                  </Link>
                                </OverlayTrigger>
                              </li>
                              <li className="nav-item nav-item-trash" role="presentation">
                                <OverlayTrigger overlay={<Tooltip>{Translate("Trash")}</Tooltip>}>
                                  <a className="nav-link" href="#">
                                    <i className="bi bi-trash-fill" />
                                  </a>
                                </OverlayTrigger>
                              </li>
                            </ul>
                          </div>
                          {/*<div
                          className="CD_Avatar"
                          style={{
                            background: "background: url(images/aHNPPA8z.jpg) no-repeat center / cover",
                          }}
                        >
                          <img src="images/aHNPPA8z.jpg" className="invisible img-fluid" />
                        </div>*/}
                          <div className="eventTimeTagRow">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="row row-cols-lg-auto p-3 g-1 align-items-center eventDateRow">
                                  <div className="col-12">
                                    <input
                                      type="datetime-local"
                                      className="form-control form-control-lg event_start_datetime"
                                      name="event_start_datetime"
                                      placeholder="{Translate('Start Date/Time')}"
                                      defaultValue={eventTime}
                                      step="900"
                                      required
                                      onChange={e => {
                                        setEventTime(e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <select
                                      className="form-select form-select-lg eventTimeDurationInput"
                                      key={eventDuration}
                                      defaultValue={eventDuration}
                                      onChange={e => {
                                        setEventDuration(e.target.value)
                                      }}
                                    >
                                      <option value="All Day">{Translate("All Day")}</option>
                                      <optgroup label="== Duration ==">
                                        <option value="1">{Translate("1 min")}</option>
                                        <option value="6">{Translate("6 mins")}</option>
                                        <option value="15">{Translate("15 mins")}</option>
                                        <option value="30">{Translate("30 mins")}</option>
                                        <option value="45">{Translate("45 mins")}</option>
                                        <option value="60">{Translate("1 hr")}</option>
                                        <option value="120">{Translate("2 hrs")}</option>
                                        <option value="240">{Translate("4 hrs")}</option>
                                        <option value="360">{Translate("6 hrs")}</option>
                                        <option value="720">{Translate("12 hrs")}</option>
                                        <option value="1440">{Translate("24 hrs")}</option>
                                        <option value="Custom">{Translate("Custom")}</option>
                                      </optgroup>
                                    </select>
                                  </div>
                                  {eventDuration === "Custom" && (
                                    <div className="col-12 eventTimeDurationCustomInput">
                                      <input
                                        type="datetime-local"
                                        name="endTime"
                                        step="900"
                                        className="form-control form-control-lg event_end_datetime"
                                        placeholder="{Translate('End Date/Time')}"
                                        defaultValue={eventEndTime}
                                        required={eventDuration === "Custom"}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="CD_Info_Row eventTagsAndDealTypeContainer position-relative p-3">
                                  <Tags
                                    tags={tags}
                                    setTags={setTags}
                                    tempTags={tempTags}
                                    setTempTags={setTempTags}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col-lg-12 mb-3">
                      <div className="row g-0">
                        <div
                          className={`col-lg-12 mb-3 eventDescToggle${!showDescription ? " d-none" : ""}`}
                          id="editor_panel"
                        >
                          <div className="accordion-item">
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="doc_data">
                                    <Editor
                                      textareaName="content"
                                      value={content}
                                      onInit={(evt, editor) => (editorRef.current = editor)}
                                      init={{
                                        skin: false,
                                        branding: false,
                                        content_css: false,
                                        height: 500,
                                        promotion: false,
                                        plugins: [
                                          "advlist",
                                          "autolink",
                                          "lists",
                                          "link",
                                          "image",
                                          "preview",
                                          "searchreplace",
                                          "table",
                                          "lists",
                                          "link",
                                          "wordcount",
                                        ],
                                        toolbar:
                                          "undo redo| styles | " +
                                          "bold italic alignleft aligncenter " +
                                          "alignright alignjustify | bullist numlist outdent indent | image |",
                                        content_style:
                                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                      }}
                                      onEditorChange={(newValue, editor) => {
                                        setContent(editor.getContent())
                                        setMetaJson({ ...metaJson, event_note: content })
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`col-lg-12 mb-3 ${isShowPeoplePanel ? "d-block" : "d-none"}`}
                          id="people_panel"
                        >
                          <PeopleAndPermissionPanel
                            eventContactData={eventContactData}
                            selectedId={selectedId}
                            getEvent={getEvent}
                          />
                        </div>

                        <div className="col-lg-12 footer-action">
                          <a href="#" className="btnDeleteEvent" onClick={() => deleteEvent()}>
                            <i className="bi bi-trash" /> {Translate("Delete this event?")}
                          </a>
                        </div>
                        <div className="col-lg-12 footer-action">
                          <div className="row row-cols-sm-auto g-1 align-items-center justify-content-center mb-3">
                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger overlay={<Tooltip>{Translate("Add Description")}</Tooltip>}>
                                <button
                                  type="button"
                                  className="event_btnAddEventDesc"
                                  onClick={() => {
                                    setShowDescription(!showDescription)
                                  }}
                                >
                                  <i className="bi bi-justify-left" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <OverlayTrigger overlay={<Tooltip>{Translate("Add Guest/Attendees")}</Tooltip>}>
                                <button
                                  type="button"
                                  className="event_btnAddNewGuestUser"
                                  onClick={() => {
                                    setIsShowPeoplePanel(!isShowPeoplePanel)
                                  }}
                                >
                                  <i className="bi bi-person-fill-add" />
                                </button>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 footer-action-btns">
                              <>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{Translate("Location and/or Channel")}</Tooltip>}
                                >
                                  <button
                                    className="btn dropdown-toggle no-dropdown-arrow event_btnAddEventOccurance"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-geo-alt-fill" />
                                  </button>
                                </OverlayTrigger>
                                <ul className="dropdown-menu">
                                  {eventContactData.map((eventContactValue, eventContactIndex) => (
                                    <React.Fragment key={eventContactIndex}>
                                      {eventContactValue.contact.addresses?.map(
                                        (addressValue, addressIndex) => (
                                          <li key={addressIndex}>
                                            <Link
                                              to={"#"}
                                              className="dropdown-item"
                                              data-type="SettingsAddresses"
                                              onClick={() => setContactSelectedAddress(addressValue.address)}
                                            >
                                              {addressValue.address}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 text-center footer-action">
                          <div className="btn-group btnDropdownWithSeparater">
                            <button type="submit" className="btn btn-primary btn-lg">
                              <i className="bi bi-check2" /> {Translate("Save")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="visually-hidden">{Translate("Toggle Dropdown")}</span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a className="dropdown-item" href="#">
                                  <i className="bi bi-files" /> {Translate("Save as Copy")}
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <i className="bi bi-subtract" /> {Translate("Clone a Template")}
                                </a>
                              </li>
                            </ul>
                          </div>{" "}
                          {contactSelectedAddress && (
                            <button
                              type="button"
                              className="btn btn-success btn-lg btnGetDirection"
                              onClick={openAddressOnGoogleMap}
                            >
                              <i className="bi bi-geo-alt-fill" /> {Translate("Get Directions")}
                            </button>
                          )}
                          <button type="button" className="btn btn-secondary btn-lg btnEventPreview d-none">
                            <i className="bi bi-box-arrow-up-right" /> {Translate("Preview")}
                          </button>
                          <button type="button" className="btn btn-default btn-lg btnEventPublish d-none">
                            <i className="bi bi-cloud-arrow-up-fill" /> {Translate("Publish")}
                          </button>{" "}
                          {/*<button type="button" className="btn btn-default btn-lg btnOpenChatDock">
                            <OverlayTrigger overlay={<Tooltip>History</Tooltip>}>
                              <i className="bi bi-arrow-counterclockwise" />
                            </OverlayTrigger>
                          </button>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default Detail
